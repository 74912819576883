import React, { useEffect, useState } from "react";
import style from "./index.module.scss";
import buttonCancel from "../../../assets/roll/button_cancel.png";
import transferSuccess from "../../../assets/images/account/transferSuccess.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import { collapseAddress } from "../../../core/utils";

type TransferCoinSuccessStyle = {
	address: string;
	amount: number;
	transactionFee: string;
	hashTxt: string;
	onClose: () => void;
};
type ItemType = {
	left: string;
	right: string;
};
export default function TransferCoinSuccessModal({
	address,
	amount,
	transactionFee,
	hashTxt,
	onClose,
}: TransferCoinSuccessStyle) {
	const RenderWidget = ({ left, right }: ItemType) => {
		return (
			<div className={style.bodyItem}>
				<p className={style.itemLeft}>{left}</p>
				<p className={style.itemRight}>{right}</p>
			</div>
		);
	};

	const formatDate = (date: Date) => {
		const day = date.getDate();
		const month = date.toLocaleString("default", { month: "short" });
		const year = date.getFullYear();
		const time = date.toLocaleTimeString([], {
			hour: "2-digit",
			minute: "2-digit",
		});

		return `${day} ${month} ${year} at ${time}`;
	};
	const currentDate = new Date();

	return (
		<div className={style.main}>
			<div className={`${style.popup}`}>
				<img
					src={buttonCancel}
					width={36}
					height={38}
					alt="button cancel"
					className={style.button__close}
					onClick={onClose}
				/>
				<img
					src={transferSuccess}
					alt="transferSuccess"
					className={style.logoStyle}
				/>
				<div className={style.childStyle}>
					<p className={style.swapcointTile}>
						Successfully transferred
					</p>
					<RenderWidget
						left="Address:"
						right={collapseAddress(address)}
					/>
					<RenderWidget left="Network:" right="APT" />
					<RenderWidget left="Amount" right={amount.toString()} />
					<RenderWidget
						left="Transaction fee"
						right={transactionFee}
					/>
					<RenderWidget
						left="Transfer date: "
						right={formatDate(currentDate)}
					/>
					<RenderWidget
						left="Hash TXT:"
						right={collapseAddress(hashTxt)}
					/>
					<div
						style={{
							height: "4px",
						}}
					></div>
					<CircleButton title="OK" onTap={onClose} />
				</div>
			</div>
			<div className={`${style.modalOverlay}`}></div>
		</div>
	);
}
