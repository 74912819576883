/** @format */

import style from "./index.module.scss";
import cardImg from "../../../assets/images/card/Setcompleted.png";
import GameButton from "../../commonButton/gameButton/GameButton";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";

type ModalExchangeRewardType = {
	img: string;
	onClose: () => void;
	onClaim: () => void;
};

export default function ModalExchangeReward({
	img,
	onClose,
	onClaim,
}: ModalExchangeRewardType) {
	return (
		<div className={style.modalOverlay} onClick={onClose}>
			<div className={style.main}>
				<img src={cardImg} alt="cardImg" height={60} />
				<p className={style.titleTEXT}>You've got</p>
				<img
					src={img}
					alt="img"
					style={{
						marginBottom: "40px",
					}}
				/>
				<CircleButton title="Exchange Reward" onTap={onClaim} />
			</div>
		</div>
	);
}
