// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";

export const LocalStorageKeys = {
	keylessAccounts: "@aptos-connect/keyless-accounts",
};

export const devnetClient = new Aptos(
	new AptosConfig({ network: Network.MAINNET })
);

/// FIXME: Put your client id here
export const GOOGLE_CLIENT_ID =
	"155327043823-869b4roln788gb61suem3b736hvjth37.apps.googleusercontent.com";
	// export const GOOGLE_CLIENT_ID =
	// "1032664511853-2gc6g9ibedg3a4qjgvs7po2v6gei1ca0.apps.googleusercontent.com";	

