import { isMobile } from "react-device-detect";
import React, { useEffect, useRef, useState } from "react";
import { RWebShare } from "react-web-share";
import styles from "./index.module.scss";
import ProfileTeleMiniApp from "./components/ProfileTeleMiniApp";
import UnityComponent from "./components/UnityComponent";
import QuestPage, { requestCheckedIn } from "./components/QuestComponent";
import {
	apiPillageJourneyInfo,
	apiRegisterAccountWithAddress,
	requestSkipBuildingApi,
} from "../../../services/claimApiService";
import {
	apiGetMyAccount,
	checkedInRequest,
	requestUpdateProfile,
} from "../../../services/accountApiService";
import { parseEther } from "viem";
import { decodeJwt, encodeJwt } from "../../../utils/extension";
import { getRandomString, tabData } from "../../../utils/constants/pages";
import { useLocation } from "react-router-dom";
import IsLandComponent from "./components/IsLand";
import { aptos, MODULE_ADDRESS } from "../../../aptosConfig";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import AptosWalletConnect from "./walletConnect";
import ConfirmTransactionModal from "../../../commonsUi/modals/confirmTransaction/confirmPopUp";
import ConfirmCheckinModal from "../../../commonsUi/modals/confirmCheckin";
import CardPage from "./components/Card/CardPage";
import TeleEnergyShop from "./components/teleEnergyShop/TeleEnergyShop";
import { stavaxAccount } from "../../../connectStavax";
import AccountPage from "./components/Account";
import ConnectWalletModal from "../../../commonsUi/modals/reachUstd";
import RankingPage from "./components/ranking/RankingPage";
import ChoseWallet from "../../../commonsUi/modals/ChooseWallet";

import {
	useAccount,
	useBalance,
	usePublicClient,
	useWriteContract,
} from "wagmi";
import {
	SHOP_WALLET_CONTRACT_ADDRESS_BASE,
	abi,
} from "../../../ABI/shopWallet";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { useKeylessAccounts } from "../../../core/useKeylessAccounts";
import {
	feePayer,
	getLocalKeylessAccount,
} from "../../../utils/aptos/aptosStorage";
import { devnetClient } from "../../../core/constants";
import PaymentAptosAndTon from "../PaymentAptosTon";
import { WelcomeChest } from "./components/WelcomeChest";
interface SkipBuildingData {
	buildingId?: string;
	islandId?: string;
}

interface TelegramWebApp {
	ready: () => void;
	expand: () => void;
}
// Declare the Telegram WebApp interface
interface TelegramWebApp {
	initData: string;
	initDataUnsafe: {
		user: {
			id: number;
			first_name: string;
			last_name?: string;
			username?: string;
			language_code?: string;
			photo_url?: string;
		};
		[key: string]: any;
	};
}

interface Window {
	Telegram: {
		WebApp: TelegramWebApp;
	};
}

declare global {
	interface Window {
		Telegram?: {
			WebApp?: TelegramWebApp;
		};
	}
}
const TeleAppPage: React.FC = () => {
	const { activeAccount, disconnectKeylessAccount } = useKeylessAccounts();
	const [screen, setScreen] = useState(1);
	const [indexTab, setIndexTab] = useState(2);
	const [isUIVisible, setIsUIVisible] = useState(false);
	const [dataRenderHeader, setDataRenderHeader] = useState();
	const [isJoinPillage, setIsJoinPillage] = useState(false);
	const [isClient, setIsClient] = useState(false);
	const [isLoadingFirst, setIsLoadingFirst] = useState(false);
	const [deviceIsMobile, setDeviceIsMobile] = useState(false);
	const [accountInfo, setAccountInfo] = useState(null);
	const [userIdTele, setUserIdTele] = useState<string | string[] | undefined>(
		undefined
	);
	const [isShowConnectWalelt, setShowConnectWallet] = useState(false);
	const [isShowConnectWalelt1, setShowConnectWallet1] = useState(false);
	const shareRef = useRef<HTMLParagraphElement>(null);
	const [isCheckedIn, setCheckIn] = useState<string | null>(null);
	const [isClaim, setClaim] = useState(false);
	const [isRecallApi, setRecallApi] = useState(false);
	const location = useLocation();
	const handledEvents = useRef(new Set());
	const [isSkipBuilding, setSkipBuilding] = useState<SkipBuildingData>({});
	const [isShowCheckin, setShowCheckin] = useState(false);
	const [dataBuyEnneryCocos, setDataBuyEnneryCocos] = useState(null);
	const [dataBuyEnneryCocosPremium, setDataBuyEnneryCocosPremium] =
		useState(null);
	const [isShowConnectWallet, setConnectWallet] = useState(false);
	const [isBUY_LEGACY, setBUY_LEGACY] = useState(false);
	const { writeContractAsync } = useWriteContract();
	const publicClient = usePublicClient();
	const account = useAccount();
	const [isLogedIn, setLogedIn] = useState(false);
	const [tonConnectUI, setOptions] = useTonConnectUI();
	const [showPreLoading, setShowPreLoading] = useState<boolean>(() => {
		return localStorage.getItem("__showPreLoad") ? false : true;
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const userId: any = params.get("user_id");
		const referId = params.get("refer_id");
		const userName = params.get("user_name");
		setUserIdTele(userId);
		localStorage.setItem("_userId", userId);
		handleLoginWhenWelcome(userId, referId, userName);
	}, [isRecallApi]);

	const handleTransfer = async (id: number, amount: string) => {
		if (account?.address === undefined) {
			const session = await stavaxAccount.connect();
		} else {
			try {
				stavaxAccount.openTgBot();
				const hash = await writeContractAsync({
					abi,
					address: SHOP_WALLET_CONTRACT_ADDRESS_BASE,
					functionName: "deposit",
					value: parseEther(amount),
					args: [id],
				});
				await publicClient?.waitForTransactionReceipt({
					hash: hash,
				});
			} catch (e) {
				console.log({ e });
			}
		}
	};

	const ethBalance = useBalance({
		address: account?.address,
	});

	const buyItem = async (id: number, usdtPrice: number) => {
		try {
			const bnbUsdtPriceResponse = await fetch(
				"https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT"
			);
			const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
			const value =
				Number(ethBalance?.data?.formatted) * bnbUsdtPriceData.price;
			handleTransfer(id, (usdtPrice / bnbUsdtPriceData.price).toString());
		} catch (error) {
			console.error("Error fetching BNB/USDT price:", error);
		}
	};
	const handleShare = async () => {
		const shareLink = `https://t.me/Slime_Revolution_bot?start=${userIdTele}`;
		if (navigator.share) {
			try {
				await navigator.share({
					title: "Slime Revolution",
					text: "Play Slime Revolution with me!",
					url: shareLink,
				});
			} catch (error) {}
		}
	};

	const copyTextToClipboard = (text: string) => {
		var textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			var successful = document.execCommand("copy");
			var msg = successful ? "successful" : "unsuccessful";
		} catch (err) {
			console.error("Oops, unable to copy", err);
		}
		document.body.removeChild(textArea);
	};
	useEffect(() => {
		const handleMessage = (event: any) => {
			console.log({ event });
			const eventType = event.data.type;
			if (!handledEvents.current.has(eventType)) {
				handledEvents.current.add(eventType);
				switch (eventType) {
					case "INVITE":
						if (shareRef.current) {
							shareRef.current.click();
						}
						break;
					case "SKIP_BUILDING":
						setSkipBuilding({
							buildingId: event.data.data.buildingId,
							islandId: event.data.data.islandId,
						});
						// skipBuilding(
						// 	event.data.data.buildingId,
						// 	event.data.data.islandId
						// );
						break;
					case "CHECKIN":
						setShowCheckin(true);
						break;
					case "BUY_ENERGY":
						const data = event.data.data;
						if (tonConnectUI.connected) {
							tonConnectUI.disconnect();
						}
						setDataBuyEnneryCocos(data);
						break;
					case "BUY_PREMIUM_WHEEL":
						const dataPrm = event.data.data;
						setDataBuyEnneryCocosPremium(dataPrm);
						break;

					case "BUY_LEGACY":
						const dataPrm1 = event.data.data;
						setBUY_LEGACY(true);
						setDataBuyEnneryCocosPremium(dataPrm1);
						break;

					case "CONNECT_WALLET":
						console.log("safsdf");
						setShowConnectWallet1(true);
						break;
					case "SPIN":
						sendTransactionAPT();
						break;

					case "CHANGE_TAB":
						const index = event.data.index;
						setIndexTab(index);
						break;

					case "COPY_TO_CLIPBOARD":
						copyTextToClipboard(
							`https://t.me/Slime_Revolution_bot?start=${userIdTele}`
						);
						break;
					default:
						break;
				}

				setTimeout(() => {
					handledEvents.current.delete(eventType);
				}, 1000);
			}
		};

		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	useEffect(() => {
		if (window.Telegram && window.Telegram.WebApp) {
			const initData = window.Telegram.WebApp.initData;
			const initDataParsed = new URLSearchParams(initData);
			console.log({ initDataParsed });
			console.log("initData", initData);
			const userString = initDataParsed.get("user");
			const startParam = initDataParsed.get("start_param");
			if (userString) {
				console.log({ startParam });
				const user = JSON.parse(userString);
				localStorage.setItem("user_telegramaa", JSON.stringify(user));
				setUserIdTele(user.id);
				localStorage.setItem("_userId", user.id);
				handleLoginWhenWelcome(user.id, startParam, user.username);
			}
		}
	}, [isRecallApi]);

	const handleCallApiPillageJourneyInfoAgain = async () => {
		const responsePillageJourneyInfo: any = await apiPillageJourneyInfo();
		if (
			responsePillageJourneyInfo?.success &&
			responsePillageJourneyInfo?.data?.is_join_pillage === true
		) {
			localStorage.setItem(
				"journey_info",
				JSON.stringify(responsePillageJourneyInfo?.data)
			);
			setIsLoadingFirst(false);
			setScreen(3);
			setIndexTab(3);
			setIsJoinPillage(true);
		} else {
			setIsLoadingFirst(false);
		}
	};

	const handleCallApiInFoUser = async () => {
		const responseInfoFarming: any = await apiGetMyAccount();
		if (responseInfoFarming) {
			setAccountInfo(responseInfoFarming?.data);
			localStorage.setItem(
				"info_data_user",
				JSON.stringify(responseInfoFarming?.data)
			);
		}
	};

	const handleLoginWhenWelcome = async (
		userId: number | string,
		referId: any,
		userName: any
	) => {
		localStorage.removeItem("dataInfoFarming");
		localStorage.removeItem("info_data");
		localStorage.removeItem("journey_info");
		localStorage.removeItem("info_data_user");
		const value = {
			TelegramId: userId,
			FirstReferId: referId,
			UserName: userName,
		};

		const payLoadData = await encodeJwt(value);
		const responseRegisterAccount: any =
			await apiRegisterAccountWithAddress({
				value: payLoadData,
			});
		if (responseRegisterAccount?.success) {
			setShowPreLoading(responseRegisterAccount?.data?.first_user);
			if (!responseRegisterAccount?.data?.first_user) {
				localStorage.setItem("__showPreLoad", "1");
			}
			localStorage.setItem(
				"_gameAccessToken",
				responseRegisterAccount?.data?.access_token
			);
			localStorage.setItem("_AddressAccount", payLoadData);
			localStorage.setItem(
				"info_data",
				JSON.stringify(responseRegisterAccount?.data)
			);
			handleCallApiPillageJourneyInfoAgain();
			handleCallApiInFoUser();
			setIsUIVisible(true);
			setIndexTab(2);
			setTimeout(() => {
				setShowConnectWallet(true);
			}, 2000);
		}
	};

	useEffect(() => {
		if (isMobile) {
			setDeviceIsMobile(true);
		} else {
			setDeviceIsMobile(false);
		}
	}, []);

	useEffect(() => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.ready();
			handleExpand();
		}
	}, []);

	const handleExpand = () => {
		if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
			window.Telegram.WebApp.expand();
		}
	};

	function getQueryParams() {
		const params = new URLSearchParams(window.location.search);
		const queryParams: { [key: string]: string } = {};
		params.forEach((value, key) => {
			queryParams[key] = value;
		});
		return queryParams;
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setCheckIn(null);
		}, 2000);
		return () => clearTimeout(timer);
	}, [isCheckedIn]);

	useEffect(() => {
		console.log("asdffsdfs");
		console.log(getAptosClient?.accountAddress);

		console.log(
			getAptosClient === undefined || getAptosClient!.isExpired()
		);
		const queryParams = getQueryParams();
	}, []);

	const getAptosClient = getLocalKeylessAccount();
	console.log({ getAptosClient });

	const sendTransaction = async () => {
		const foundAccount = await devnetClient.fundAccount({
			accountAddress: getAptosClient!.accountAddress,
			amount: 600000000,
		});

		const accountCoinsData = await devnetClient.getAccountCoinsData({
			accountAddress: getAptosClient!.accountAddress.toString(),
		});
		const transaction = await devnetClient.transferCoinTransaction({
			sender: getAptosClient!.accountAddress,
			recipient:
				"0xe012822f44b2765789a8b50cded4d71874a264d55b28eb61cfbe38abb1c6dd8b",
			amount: 100000000,
		});
		const committedTxn = await devnetClient.signAndSubmitTransaction({
			signer: getAptosClient!,
			transaction,
		});
		console.log({ committedTxn });
		console.log({ accountCoinsData });
		console.log({ foundAccount });
	};

	const sendTransactionAPT = async () => {
		try {
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getAptosClient!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::lottery_slime::decide_winner`,
					functionArguments: [],
				},
			});
			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getAptosClient!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			console.log({ response });
		} catch (e) {
			console.log({ e });
		}
	};

	return isShowConnectWalelt1 && !isLogedIn ? (
		<AptosWalletConnect
			onClose={() => {
				setLogedIn(true);
				setShowConnectWallet1(false);
				setRecallApi(!isRecallApi);
				setShowPreLoading(false);
			}}
		/>
	) : (
		<div className={styles.main1}>
			{Object.keys(isSkipBuilding).length > 0 && (
				<ConfirmTransactionModal
					noConnect={() => {
						setSkipBuilding({});
						setTimeout(() => {
							setShowConnectWallet(true);
						}, 200);
					}}
					onClose={() => setSkipBuilding({})}
					isLeadId={isSkipBuilding?.islandId}
					buildingId={isSkipBuilding?.buildingId}
				/>
			)}
			{dataBuyEnneryCocosPremium !== null && (
				<PaymentAptosAndTon
					isBUY_LEGACY={isBUY_LEGACY}
					dataPros={dataBuyEnneryCocosPremium}
					onClose={() => {
						setDataBuyEnneryCocosPremium(null);
					}}
				/>
			)}
			{isShowCheckin && (
				<ConfirmCheckinModal
					noConnect={() => {
						setSkipBuilding({});
						setTimeout(() => {
							setShowConnectWallet(true);
						}, 200);
					}}
					onClose={() => setShowCheckin(false)}
					statusCheckin={(value) => {
						if (value) {
							setClaim(true);
							setCheckIn("Check in successfully");
						} else {
							setCheckIn("Check in failure");
						}
					}}
				/>
			)}
			{dataBuyEnneryCocos !== null && (
				<ChoseWallet
					dataPros={dataBuyEnneryCocos}
					onClose={() => setDataBuyEnneryCocos(null)}
					onClickStavax={async () => {
						const dataDecode: any = await decodeJwt(
							dataBuyEnneryCocos!
						);
						buyItem(dataDecode!.id!, dataDecode!.price!);
						console.log({ dataDecode });
						setDataBuyEnneryCocos(null);
					}}
					onClickMetatask={() => {
						window.open(
							`https://metamask.app.link/dapp/internaltest.slimerevolution.com/payment?value=${dataBuyEnneryCocos}`,
							"_blank"
						);
						setDataBuyEnneryCocos(null);
					}}
				/>
			)}
			{/* {isShowConnectWallet && (
				<ConnectWalletModal onClose={() => setConnectWallet(false)} />
			)} */}
			<div className={`${styles.main} `}>
				<RWebShare
					data={{
						title: "Slime Revolution",
						text: "Play Slime Revolution with me!",
						url: `https://t.me/Slime_Revolution_bot?start=${userIdTele}`,
					}}
					onClick={() => console.log("shared successfully!")}
				>
					<p ref={shareRef}></p>
				</RWebShare>
				<div>
					{tabData.map((item, index) => {
						if (indexTab === item?.id) {
							return (
								<div key={index}>{item?.renderComponent}</div>
							);
						}
					})}
					{/* <div
							style={{
								display: indexTab === 2 ? "block" : "none",
							}}
						>
							<UnityComponent
								visible={indexTab === 1}
								setIsClient={setIsClient}
							/>
						</div> */}
					{/* {indexTab === 3 && <TeleEnergyShop />} */}
					{indexTab === 3 && <RankingPage />}
					{indexTab === 4 && <AccountPage />}
					{indexTab === 1 && <CardPage />}
					{indexTab === 0 && <QuestPage />}

					{showPreLoading && (
						<WelcomeChest fncShowPreLoading={setShowPreLoading} />
					)}

					<div
						style={{
							display: indexTab === 2 ? "block" : "none",
						}}
					>
						<IsLandComponent
							isMarginBT={
								accountInfo &&
								(accountInfo!["actort"]["AptWallet"] === "" ||
									accountInfo!["actort"]["AptWallet"] ===
										null)
									? true
									: false
							}
							visible={indexTab === 2}
							setIsClient={setIsClient}
						/>
					</div>
					{/* <div
							style={{
								display: indexTab === 0 ? "block" : "none",
							}}
						>
							<QuestPage
							/>
						</div> */}
					{isCheckedIn != null && (
						<div
							className={styles.checkedInStyle}
						>{`${isCheckedIn}`}</div>
					)}
				</div>
				<div>
					<>
						<div className={styles.shadowBottom}></div>
						<div className={styles.bottomNavigation}>
							{tabData.map((e, index) => (
								<div
									key={index}
									onClick={() => {
										setIndexTab(index);
									}}
									id="button"
								>
									<img
										src={
											index === indexTab
												? e.imgActive
												: e.imgInActive
										}
										alt={`${e.imgActive}`}
										height={45}
									/>
								</div>
							))}
						</div>
					</>
				</div>
			</div>
		</div>
	);
};

export default TeleAppPage;
