import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useKeylessAccounts } from "../src/core/useKeylessAccounts";
import { jwtDecode } from "jwt-decode";
import { EphemeralKeyPair } from "@aptos-labs/ts-sdk";
import { aptos } from "./aptosConfig";
import useEphemeralKeyPair from "./core/useEphemeralKeyPair";
import { devnetClient } from "./core/constants";
import { storeKeylessAccount } from "./utils/aptos/aptosStorage";
function CallbackPage() {
	const isLoading = useRef(false);
	const switchKeylessAccount = useKeylessAccounts(
		(state) => state.switchKeylessAccount
	);
	const keypare = useKeylessAccounts((state) => state.getEphemeralKeyPair);
	const navigate = useNavigate();

	const fragmentParams = new URLSearchParams(
		window.location.hash.substring(1)
	);
	const idToken = fragmentParams.get("id_token");

	//
	const parseJWTFromURL = (url: string): string | null => {
		const urlObject = new URL(url);
		const fragment = urlObject.hash.substring(1);
		const params = new URLSearchParams(fragment);
		return params.get("id_token");
	};

	// window.location.href = https://.../login/google/callback#id_token=...
	const jwt = parseJWTFromURL(window.location.href);
	const payload = jwtDecode<{ nonce: string }>(jwt!);
	const jwtNonce = payload.nonce;
	const ephemeralKeyPair = useEphemeralKeyPair();

	const getAccount = async () => {
		if (jwt != null) {

			const keylessAccount = await aptos.deriveKeylessAccount({
				jwt,
				ephemeralKeyPair,
			});

      storeKeylessAccount(keylessAccount);

		}
	};
	//
	useEffect(() => {
		if (isLoading.current) return;
		isLoading.current = true;

		async function deriveAccount(idToken: string) {
			try {
				await switchKeylessAccount(idToken);
				navigate("/");
			} catch (error) {
				navigate("/");
			}
		}

		if (!idToken) {
			navigate("/");
			return;
		}

		deriveAccount(idToken);
		getAccount();
	}, [idToken, isLoading, navigate, switchKeylessAccount]);

	return (
		<div className="flex items-center justify-center h-screen w-screen">
			<div className="relative flex justify-center items-center border rounded-lg px-8 py-2 shadow-sm cursor-not-allowed tracking-wider">
				<span className="absolute flex h-3 w-3 -top-1 -right-1">
					<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
					<span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
				</span>
				Redirecting...
			</div>
		</div>
	);
}

export default CallbackPage;
