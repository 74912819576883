// Marketplace.js

import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import imgDemo from "../../../assets/images/card/demoCard.png";
import dividerImg from "../../../assets/images/teleImage/dividerImg.png";
import CommonPriceButton from "../../CommonPriceButton";
import {
	getMarkePlace,
	requestBuyCardNft,
} from "../../../services/accountApiService";
import CardMarketPage from "./index";
import cardImg from "@/assets/images/teleImage/brgCard.png";
import { devnetClient } from "../../../core/constants";
import AptosWalletConnect, {
	getAptosClient,
} from "../../../components/page/TeleAppPage/walletConnect";
import {
	ADDRESS_GET_MONEY,
	convertOctasToApt,
	getUserOwnedAptogotchis,
	MARKET_PLACE_CONTRACT,
	MODULE_ADDRESS,
} from "../../../aptosConfig";
import { APTOS_COIN } from "@aptos-labs/ts-sdk";
import { feePayer } from "../../../utils/aptos/aptosStorage";
import ChoosePlanFarming from "../choosePlanFarming";
import Slime from "../../../assets/roll/Slime.svg";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import { encodeJwt } from "../../../utils/extension";
import { collapseAddress } from "../../../core/utils";
import LoadingCommon from "../../CommonUI/LoadingApp";
import ModalCoppyAddress from "../ModalCopyAddress";
type CardMarketPlaceType = {
	idCard: number;
	onUpdate: () => void;
};
const CardMarketplace = ({ idCard, onUpdate }: CardMarketPlaceType) => {
	const [listNft, setListNft] = useState<MarketResponse | null>(null);
	const [isBuySuccess, setBuySuccess] = useState(false);
	const [isShowLoading, setShowLoading] = useState(false);
	const [isShowLoading1, setShowLoading1] = useState(false);
	const [insufficientCoin, setInsufficientCoin] = useState(false);
	const [isShowConnectWallet, setConnectWallet] = useState(false);

	const requestMarketPlace = async (id: number) => {
		setShowLoading1(true);
		setListNft(null);
		const res: any = await getMarkePlace(id);
		setListNft(res);
		console.log(res);
		setShowLoading1(false);
	};

	const submitBuyCard = async (
		cardId: number,
		tokenId: string,
		buyTokenId: string
	) => {
		const value = {
			card_id: cardId,
			token_id: tokenId,
			buy_token_id: buyTokenId,
		};
		const payLoadData = await encodeJwt(value);
		requestBuyCardNft({ value: payLoadData });
	};
	useEffect(() => {
		requestMarketPlace(idCard);
	}, [isShowLoading]);

	const buyCardNft = async (
		price: number,
		listingObjectAddr: string,
		cardId: number,
		tokenId: string
	) => {
		setShowLoading(true);

		const bnbUsdtPriceResponse = await fetch(
			"https://api.binance.com/api/v3/ticker/price?symbol=APTUSDT"
		);
		const bnbUsdtPriceData = await bnbUsdtPriceResponse.json();
		const accountCoinsData: any = await devnetClient.getAccountCoinsData({
			accountAddress: getAptosClient!.accountAddress.toString(),
		});
		if (
			accountCoinsData.lenght < 0 ||
			convertOctasToApt(accountCoinsData[0].amount) < price
		) {
			setShowLoading(false);
			setInsufficientCoin(true);
		}
		try {
			const transaction = await devnetClient.transaction.build.simple({
				withFeePayer: true,
				sender: getAptosClient!.accountAddress,
				data: {
					function: `${MODULE_ADDRESS}::${MARKET_PLACE_CONTRACT}::purchase`,
					typeArguments: [APTOS_COIN],
					functionArguments: [listingObjectAddr, ADDRESS_GET_MONEY],
				},
			});

			const feePayerAuthenticator =
				devnetClient.transaction.signAsFeePayer({
					signer: feePayer,
					transaction,
				});
			const senderAuthenticator = devnetClient.transaction.sign({
				signer: getAptosClient!,
				transaction,
			});
			const committedTransaction =
				await devnetClient.transaction.submit.simple({
					transaction,
					senderAuthenticator: senderAuthenticator!,
					feePayerAuthenticator: feePayerAuthenticator,
				});
			const response = await devnetClient.waitForTransaction({
				transactionHash: committedTransaction.hash,
			});
			const cardMinted = await getUserOwnedAptogotchis();
			await submitBuyCard(cardId, tokenId, cardMinted.token_data_id);
			await requestMarketPlace(idCard);
			onUpdate();
			setShowLoading(false);
			setBuySuccess(true);
			console.log({ response });
		} catch (e: any) {
			if (e?.message === "EphemeralKeyPair is expired") {
				setConnectWallet(true);
			}
			console.log({ e });
			setShowLoading(false);
			console.log({ e });
		}
	};

	return (
		<div className={styles.marketplace}>
			{!isShowLoading1 &&
				listNft?.data.list_cards &&
				listNft?.data.list_cards.map((item, index) => (
					<div key={index}>
						<div className={styles.card}>
							<img
								src={item.Card.CardImage}
								alt="Item"
								className={styles.cardImage}
							/>
							<div className={styles.cardDetails}>
								<p className={styles.id}>
									<span>ID:</span> <span>{item.Card.Id}</span>
								</p>
								<p className={styles.price}>
									<span>Price:</span>{" "}
									<span>{item.Price} APT</span>
								</p>
								<h5>
									<span className={styles.grayStyle}>
										Owner:
									</span>{" "}
									<span className={styles.grayStyle}>
										{item.User.AptWallet
											? collapseAddress(
													item.User.AptWallet
											  )
											: "0x00000"}
									</span>
								</h5>
							</div>
							<div className={styles.buyStyle}>
								<CommonPriceButton
									onClick={() =>
										buyCardNft(
											item.Price,
											item.MarketTokenId,
											item.CardId,
											item.TokenIdCard
										)
									}
								>
									<span className={styles.priceStyle}>
										Buy
									</span>
								</CommonPriceButton>
							</div>
						</div>
						<img
							src={dividerImg}
							alt="dividerImg"
							style={{
								width: "98%",
							}}
						/>
					</div>
				))}
			{isBuySuccess && (
				<ChoosePlanFarming
					iconPopup={Slime}
					onClose={() => setBuySuccess(false)}
				>
					<div
						style={{
							marginTop: "35%",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							rowGap: "16px",
						}}
					>
						<p className={styles.listSuccessfulStyle}>
							List Successful
						</p>
						<CircleButton
							title="Sell more"
							onTap={() => {
								setBuySuccess(false);
							}}
						/>
						{/* <CircleButton
							isGrayColor={true}
							title="check my collection"
							onTap={() => setBuySuccess(false)}
						/> */}
					</div>
				</ChoosePlanFarming>
			)}
			{insufficientCoin && (
				<ModalCoppyAddress
					addressWallet={getAptosClient!.accountAddress.toString()}
					onClose={() => setInsufficientCoin(false)}
				/>
			)}
			{isShowConnectWallet && (
				<AptosWalletConnect
					onClose={() => {
						setConnectWallet(false);
					}}
				/>
			)}
			<LoadingCommon
				visible={isShowLoading}
				width={80}
				height={80}
				isLeft10={true}
			/>
		</div>
	);
};

export default CardMarketplace;
