import { useEffect, useState } from "react";
import iconCopy from "../../../assets/images/teleImage/icon_copy.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import ChoosePlanFarming from "../choosePlanFarming";
import styles from "./index.module.scss";
import { collapseAddress } from "../../../core/utils";
interface IModalDeposit {
	addressWallet?: string;
	onClose?: () => void;
}
const ModalDeposit = ({ addressWallet, onClose }: IModalDeposit) => {
	const [showCheckIn, setShowCheckIn] = useState<boolean>(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowCheckIn(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, [showCheckIn]);
	return (
		<ChoosePlanFarming showIcon={false} onClose={onClose}>
			<div className={styles.deposit}>
				<h3 className={styles.deposit__title}>Deposit APT</h3>
				<p className={styles.deposit__content}>
					You can simply transfer APT to the following address
				</p>
				{addressWallet && (
					<div className={styles.deposit__address}>
						<p>{collapseAddress(addressWallet)}</p>
						<img
							src={iconCopy}
							alt="iconCopy"
							width={25}
							onClick={() => setShowCheckIn(true)}
						/>
					</div>
				)}
				{showCheckIn && <div className={styles.copyStyle1}>Copied</div>}
				<CircleButton
					isGrayColor={false}
					title="Click to copy address"
					onTap={() => setShowCheckIn(true)}
				/>
			</div>
		</ChoosePlanFarming>
	);
};

export default ModalDeposit;
