import React, { useEffect, useState } from "react";
import CommonPriceButton from "../../../../../../commonsUi/CommonPriceButton";
import styles from "./index.module.scss";
import { collapseAddress } from "../../../../../../core/utils";
import dividerImg from "../../../../../../assets/images/teleImage/dividerImg.png";
import { getCardUnList } from "../../../../../../services/accountApiService";
import SellCardModal from "../../../../../../commonsUi/modals/ModalSellCard";
export default function UnlistCardNFT() {
	const [unlistCard, setUnlistCard] = useState<ResponseDataUnList | null>(
		null
	);
	const [indexSelected, setIndexSelected] = useState(0);
	const [isShowSellCard, setShowCard] = useState(false);
	const getCarListed = async () => {
		const resCardUnList: any = await getCardUnList();
		setUnlistCard(resCardUnList);
		console.log({ resCardUnList });
	};

	useEffect(() => {
		getCarListed();
	}, []);

	return (
		<div className={styles.main}>
			{unlistCard &&
				unlistCard?.data.map((e, i) => (
					<div>
						<div className={styles.card} key={i}>
							<img
								src={e.Card.CardImage}
								alt="Item"
								className={styles.cardImage}
							/>
							<div className={styles.cardDetails}>
								<p className={styles.id}>
									<span>ID Card:</span> <span>{e.Id}</span>
								</p>
								<p className={styles.price}>
									<span>Highest Price:</span>{" "}
									<span>{e.info_market.highest} APT</span>
								</p>
								<p className={styles.price}>
									<span>Floor Price:</span>{" "}
									<span>{e.info_market.floor_price} APT</span>
								</p>
							</div>
							<div className={styles.buyStyle}>
								<CommonPriceButton
									onClick={() => {
										setIndexSelected(i);
										setShowCard(true);
									}}
								>
									<span className={styles.priceStyle}>
										Sell
									</span>
								</CommonPriceButton>
							</div>
						</div>
						<img
							src={dividerImg}
							alt="dividerImg"
							style={{
								width: "98%",
							}}
						/>
					</div>
				))}

			{isShowSellCard && (
				<SellCardModal
					onUpdate={() => {
						setShowCard(false);
						getCarListed();
					}}
					cardId={unlistCard?.data![indexSelected].CardId}
					onClose={() => setShowCard(false)}
					iconPopup={unlistCard?.data![indexSelected].Card.CardImage}
				/>
			)}
		</div>
	);
}
