/** @format */

import style from "./index.module.scss";
import bronze from "../../../assets/images/ranking/Trophy.png";
import goldImg from "../../../assets/images/ranks/gold.png";
import mythic from "../../../assets/images/ranks/mythic.png";
import platinum from "../../../assets/images/ranks/platinum.png";
import silverImg from "../../../assets/images/ranks/silver.png";
import diamond from "../../../assets/images/ranks/diamond.png";
import legendary from "../../../assets/images/ranks/legendary.png";
import CircleButton from "../../commonButton/gameButton/CircleButton/CircleButton";
import CustomPopupModal from "../CustomPopup";

type InforRankingType = {
	onClosePopup: () => void;
	indexSelected: number;
};

export default function ModalInfoRanking({
	onClosePopup,
	indexSelected,
}: InforRankingType) {
	const getText = (index: number) => {
		switch (index) {
			case 0:
				return "5,000";
			case 1:
				return "10,000";
			case 2:
				return "20,000";
			case 3:
				return "60,000";
			case 4:
				return "150,000";
			case 5:
				return "300,000";
			case 6:
				return "455,000";
		}
	};

	const images = [
		bronze,
		silverImg,
		goldImg,
		platinum,
		diamond,
		legendary,
		mythic,
	];
	return (
		<CustomPopupModal
			onClose={onClosePopup}
			iconPopup={images[indexSelected]}
		>
			<div className={style.popup__usdt_wrapper}>
				<h2 className={style.popup__usdt_title}>{"Tournament Beta"}</h2>
				<div className={style.infoCard}>
					<ul>
						<li>{`Total pool reward : ${getText(
							indexSelected
						)}tSPC`}</li>
						<li>Timeline: 18/9/2024-17/10/2024</li>
						<li>
							Prizes will be awarded to the top 100 in each group
							based on their rankings.
						</li>
						<li>
							Higher-ranked groups will receive larger rewards,
							and within each group, higher rankings will result
							in greater prizes.
						</li>
						<li>
							Rankings are based on the amount of Gold farmed from
							Islands. Gold earned from spins, steals, and cannons
							will not be counted.
						</li>
						<li>
							All Island Levels and Gold will reset to zero after
							the Beta Tournament ends.
						</li>
					</ul>
				</div>
				<div className={style.popup__usdt_list_button}>
					<div>
						<div
							style={{
								marginTop: "6px",
							}}
						>
							<CircleButton
								isGrayColor={false}
								title="Got it"
								onTap={() => {
									onClosePopup();
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</CustomPopupModal>
	);
}
